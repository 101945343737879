import React from "react"
import styled from "styled-components"
// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import { PrimaryTheme } from "../constants/theme"
import { graphql } from "gatsby"

const PrivacyPolicyPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { html } = markdownRemark
  return (
    <Layout>
      <Seo title="OGD42 - Privacy Policy" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 120,
        }}
      >
        <StyledPrivacyPolicy dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  )
}

const StyledPrivacyPolicy = styled.div`
  width: 80%;
  display: block;
  h1 {
    color: white;
    font-size: 4rem;
    border-bottom: solid 2px rgba(255, 255, 255, 0.125);
    margin-bottom: 0.8em;
    padding-bottom: 0.4em;
    letter-spacing: 0.2rem;
    text-align: center;
  }
  p {
    color: ${PrimaryTheme.text};
  }
  h2 {
    color: ${PrimaryTheme.text};
  }
  ul {
    color: ${PrimaryTheme.text};
  }
`

export default PrivacyPolicyPage

export const pageQuery = graphql`
  query PrivacyPolicy {
    markdownRemark(frontmatter: { title: { eq: "Privacy Policy" } }) {
      html
    }
  }
`
